.container {
    padding: 20px;
    max-width: 900px;    margin: 0 auto;
    color: #f0f0f0;
    font-family: 'Arial', sans-serif;
    line-height: 1.8;
    padding-top: 10rem;
  }
  
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff;
  }
  
  .sectionTitle {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #e0e0e0;
  }
  
  .list {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .list li {
    margin-bottom: 10px;
  }
  
  .lastUpdated {
    margin-top: 30px;
    font-size: 14px;
    color: #b0b0b0;
    text-align: center;
  }
  