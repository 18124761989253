@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  --primary-color: #5F61FF;
  --background-color: #101113;
  --card-background: #1a1a1a;
  --text-color: #ffffff;
  --text-secondary: #b0b0b0;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  animation: fadeIn 2s ease-in-out;
}

header {
  text-align: center;
  padding: 1.5rem 1rem;
  /* background-color: var(--card-background); */
}

h1 {
  margin: 0;
  font-size: 2.5rem;
  color: var(--text-color);
  /* animation: glow 1.5s ease-in-out infinite alternate; */
}

.subtitle {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: var(--text-secondary);
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  overflow-y: auto;
}

.hero {
  text-align: center;
  margin-bottom: 2rem;
}

.hero h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.1rem;
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto 1.5rem;
}

.products {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.product {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.product p {
  color: var(--text-secondary);
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.product ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.product li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.product li svg {
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.features {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.feature {
  text-align: center;
  max-width: 250px;
  margin-bottom: 1.5rem;
}

.feature svg {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.feature p {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.5;
}

.waitlist-button, .cta-button {
  align-self: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--text-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.waitlist-button:hover, .cta-button:hover {
  background-color: #4E4FFF;
  transform: translateY(-2px);
}

.footer {
  font-size: 1rem;
  color: var(--text-secondary);
  text-align: center;
  padding: 1rem;
  background-color: var(--card-background);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 15px var(--primary-color), 0 0 20px var(--primary-color), 0 0 25px var(--primary-color), 0 0 30px var(--primary-color), 0 0 35px var(--primary-color);
  }
  to {
    text-shadow: 0 0 10px #ffffff, 0 0 15px var(--primary-color), 0 0 20px var(--primary-color), 0 0 25px var(--primary-color), 0 0 30px var(--primary-color), 0 0 35px var(--primary-color), 0 0 40px var(--primary-color);
  }
}

@media (min-width: 768px) {
  main {
    padding: 2rem;
  }

  .product {
    padding: 2rem;
  }
}

@media (max-width: 767px) {
  body, html {
    font-size: 14px;
  }

  header {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .hero h2 {
    font-size: 1.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .product {
    padding: 1.25rem;
  }

  .product h2 {
    font-size: 1.3rem;
  }

  .feature svg {
    font-size: 2rem;
  }

  .waitlist-button, .cta-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  body, html {
    font-size: 13px;
  }

  h1 {
    font-size: 1.8rem;
  }

  .hero h2 {
    font-size: 1.3rem;
  }

  .product {
    padding: 1rem;
  }

  .feature {
    max-width: 100%;
  }
}
